import React from 'react'
import tokamaniLogo from './tokamani-logo.svg';

const style = {
  header: {
    padding: 10
  },
  logo: {
    display: "block",
    margin: "0 auto",
    maxWidth: "100%"
  }
}

export default function Header() {

  return (
    <header id="header" style={style.header}>
      <img src={tokamaniLogo} alt="Λογότυπο Γεωργία Τοκαμάνη"
      style={style.logo} />
    </header>
  )

}