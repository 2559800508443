// As seen on https://upmostly.com/tutorials/modal-components-react-custom-hooks
import React from 'react'
import ReactDOM from 'react-dom'
import './Modal.css'

const Modal = ({ isShowing, hide, src }) => isShowing ? ReactDOM.createPortal(

  <>
    <div className="modal-overlay" />

    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
        <span aria-hidden="true">&times;</span>
      </button>
      <img src={src} alt='Work sample zoomed' className="modal-img" />
    </div>

  </>, document.body
) : null

export default Modal