import React from 'react'
import espaLogo from './e-bannerespa.jpg'

const espaLogoStyle = {
  display: "block",
  margin: "0 auto",
  maxWidth: "100%"
}

export default function EspaLogo() {
    return (
        <div style={{
            padding: 20
        }}>
            <a href="/files/neofyhsweb2018.pdf" download
                rel="noreferrer">
                <img
                    src={espaLogo}
                    style={espaLogoStyle}
                    alt="Λογότυπο ΕΣΠΑ"
                />
            </a>
        </div>
    )
}
