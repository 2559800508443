import React from 'react'
import EspaBanner from '../../Components/EspaBanner/EspaBanner'

export default function Intro() {
  return (
    <section id="intro" className="section" style={{
      textAlign: "center"
    }}>
      {/* <h1>Τοκαμάνη Γεωργία</h1> */}
      <h1 style={{ marginBottom: 10 }}>ΤΟΚΑΜΑΝΗ ΓΕΩΡΓΙΑ</h1>
      <h2 style={{ fontWeight: 100 }}>Τεχνικό γραφείο - Πολιτικός Μηχανικός</h2>
      <EspaBanner />
    </section>
  )
}