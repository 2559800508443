import React from 'react'

const mapStyle = {
    border: "0",
    width: "100%"
}

export default function Map() {
    return (
        <iframe
            title="map directions"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d854.1930255512293!2d26.494259375817204!3d41.34883155210763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b30373ed5b9329%3A0x9749bd7d9d025523!2sMavromichali%2029%2C%20Didimoticho%20683%2000!5e0!3m2!1sen!2sgr!4v1605443320039!5m2!1sen!2sgr"
            height="450"
            frameBorder="0"
            style={mapStyle}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
        >
        </iframe>
    )
}
