import React from 'react'

export default function Copyright() {
  const d = new Date()
  const year = d.getFullYear()

    return (
        <div>
            Τοκαμάνη Γεωργία &copy; {year}
        </div>
    )
}
