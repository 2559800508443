import React from 'react'
import Modal from '../../Components/Modal/Modal'
import useModal from '../../Components/Modal/useModal'


export default function GalleryItem({ src }) {
  const {isShowing, toggle} = useModal()
  const imageSrc = `/img/${src}.jpg`

  return (
    <div className="gallery-item">
      <a href={imageSrc} onClick={e => {
        e.preventDefault(); 
        toggle()
      } }>

        <img src={imageSrc} alt={`portfolio number ${src}`} />
        
        <Modal 
          isShowing={isShowing}
          hide={toggle}
          src={imageSrc}
        />

      </a>
    </div>
  )
}