import React from 'react'

export default function Service(props) {

    const componentClass = props.componentClass || ''
    const iconClass = props.iconClass || ''
    const text = props.text || 'Service name'
    const HtmlTag = props.htmlTag || 'div'

    return (
        <HtmlTag className={`service-item ${componentClass}`}>
            <i className={`fas fa-check ${iconClass}`} style={{ marginRight: ".5em" }}></i>
            {text}
        </HtmlTag>
    )
}
