import React from 'react'
import PageTitle from '../../Components/PageTitle'
import Map from './Map'

export default function Contact(params) {

  return (
    <section id="contact" className="section">
      <PageTitle text="Επικοινωνία" />

      <div className="contact-info">

        <p>Γεωργία Τοκαμάνη</p>

        <p>
          <a href="tel:+306994229065">+30 6994229065</a><br />
          <a href="mailto:georgia@evrokat.net">georgia@evrokat.net</a>
        </p>

        <p>
          Μαυρομιχάλη 33, Διδυμότειχο, 68300
        </p>

        <Map />

      </div>

    </section>
  )
}