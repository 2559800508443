import React from 'react'
import PageTitle from '../../Components/PageTitle';
import GalleryItem from './GalleryItem'
import "./Photos.css";

const photoList = ["01", "02", "03", "04", "05"]

export default function Photos() {

  return (
    <section id="photos" className="section">
      <PageTitle text="Δείγματα εργασιών" />

      <div className="gallery">

        {photoList.map((photo, key) => {
          return (
            <GalleryItem src={photo} key={key}/>
          )
        })}

      </div>
    </section>
  )
}