import React from 'react'
import PageTitle from '../../Components/PageTitle'
import Service from './Service'

const servicesList = [
    'Τοπογραφικές εργασίες',
    'Πιστοποιητικά ενεργειακής απόδοσης',
    'Μελέτες ενεργειακής απόδοσης',
    'Προγράμματα εξοικονόμησης ενέργειας',
    'Οικοδομικές άδειες',
    'Αυθαίρετα',
    'Άδειες λειτουργίας',
]

export default function Services() {
    return (
        <section className="section" id="services">
            <PageTitle text="Υπηρεσίες" />
            { servicesList.map((service, key) => {
                return (
                    <Service text={service} iconClass="text-brand-red" key={key} />
                )
            })}
        </section>
    )
}
