import Header from './sections/Header/Header'
import Intro from './sections/Intro/Intro'
import Services from './sections/Services/Services'
import Photos from './sections/Photos/Photos'
import Contact from './sections/Contact/Contact'
import Footer from './sections/Footer/Footer'

function App() {
  return (
    <div className="App">
      
      <Header />

      <Intro />
      <Services />
      <Photos />
      <Contact />

      <Footer />

    </div>
  )
}

export default App