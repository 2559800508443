import React from 'react'
import Copyright from './Copyright'
import DesignedBy from './DesignedBy'

export default function Footer(params) {

  return (
    <footer id="footer" className="section">
      
      <Copyright />

      <DesignedBy />
      
    </footer>
  )
}
