import React from 'react'

export default function DesignedBy() {
    return (
        <div style={{
            marginTop: 5,
            fontSize: "80%"
        }}>
            Σχεδίαση και υλοποίηση <a href="https://rdrct.w3creations.gr" target="_blank" rel="noreferrer">
                <img
                    src="http://rdrct.w3creations.gr/img/logo_w3creations_v2-120x20.png"
                    alt="Λογότυπο w3creations"
                    height="15"
                    style={{
                        position: "relative",
                        top: 3
                    }}
                />
            </a>
        </div>
    )
}
