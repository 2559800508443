import React from 'react'

export default function PageTitle(props) {
    // const HtmlTag = props.htmlTag || 'h3'
    const text = props.text || 'Page title'

    return (
        <h3 style={{ textAlign: "center" }}>
            {text}
        </h3>
    )
}
